<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <b-carousel id="carousel-example-generic" controls indicators img-height="300">
      <b-carousel-slide :img-src="userData.nicFrontImageUrl" />
      <b-carousel-slide :img-src="userData.nicBackImageUrl" />
      <b-carousel-slide :img-src="userData.houseHoldCoverImageUrl" />
      <b-carousel-slide :img-src="userData.houseHoldOwnerImageUrl" />
      <b-carousel-slide :img-src="userData.houseHoldImageUrl" />
    </b-carousel>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCarousel,
    BCarouselSlide,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
