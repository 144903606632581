<template>
  <b-card no-body class="border-primary">
    <b-card-body>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block>
        Chỉnh sửa
      </b-button>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" block>
        Khóa tài khoản
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BButton,
  },
};
</script>

<style></style>
