<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.facePhotoImageUrl"
            :text="avatarText(userData.fullName)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
              <h5 class="card-text">IL3520</h5>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-warning" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">5,000,000</h5>
              <small>Tổng thu nhập</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">3,500,000</h5>
              <small>Tổng tiền ứng</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.role }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ userData.country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              {{ userData.phoneNumber }}
            </th>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BRow, BCol } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useUsersList from '../customer-list/useUsersList';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
};
</script>

<style></style>
