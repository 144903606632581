<template>
  <div>
    <!-- Alert: No item found -->
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-permissions-card :user-data="userData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import userStoreModule from '../customerStoreModule';
import UserViewUserInfoCard from './UserViewUserInfoCard.vue';
import UserViewUserPlanCard from './UserViewUserPlanCard.vue';
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue';
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    ToastificationContent,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = 'app-customer';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });

    store
      .dispatch('app-customer/fetchCustomer', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
  mounted() {
    if (!this.userData) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'error',
          icon: 'CheckCircleIcon',
          variant: 'danger',
          text: 'error',
        },
      });
    }
  },
};
</script>

<style></style>
